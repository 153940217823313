import LazyLoad from 'vanilla-lazyload';
import moment from 'moment';
import L from 'leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import 'jquery-smooth-scroll';

var lity = import('lity');

window.moment = moment;

window.$ = window.jQuery = require('jquery');

require('./bootstrap');
require('slick-carousel');
require('jquery-easing');
require('./components/fontawesome');

import objectFitImages from 'object-fit-images';

try {
} catch (e) {}

window.onload = () =>{
    new LazyLoad({
        elements_selector: '.lazy'
    });

    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
};

$(function() {
    objectFitImages();

    $('a').smoothScroll({
      scrollElement: $('#contents'),
      preventDefault: true,
      offset: -100
    });
});
